/*-----------------------------------------------------------------------------------------------------------*/
/* Définition du composant 'ButtonGetAllQuotesInfo' pour notre application React 'app' pour notre FrontEnd : */
/*-----------------------------------------------------------------------------------------------------------*/

/* Importation du module 'styled' de 'styled-components' */
import styled from "styled-components";
/* Importation des couleurs de notre style */
import couleurs from "../../../utils/style/couleurs";
/* Importation de notre style spécifique de button */
import { StyleButton } from "../../../utils/style/Atomes";

/* Importation de notre composant 'Chargement' */
import Chargement from "../../Chargement";
/* Importation de notre composant 'ReponseBox' */
import ReponseBox from "../ReponseBox";

/* Importation des icones FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Importation des hooks 'useState' et 'useEffect' de React */
import { useState, useEffect } from "react";

/* Importation de nos Hook personnalisés 'useTheme', 'useFetch' et 'useIdentification' */
import { useTheme, useFetch, useIdentification } from "../../../utils/hooks";

const DivButtonContaineur = styled.div`
    ${(props) =>
        props.$creationActive
            ? `
            margin: 10px 0;
            border-radius: 20px;
            background-color: ${props.theme === "clair" ? couleurs.secondaire : couleurs.tertiaire};
            display: flex;
            flex-direction: column;
            justify-content: center;
        `
            : `display: flex;
        justify-content: space-around;
        align-items: center;
        width: ${props.$width};`}
`;

const ButtonTexte = styled.span`
    font-size: ${({ $size }) => ($size ? $size : "12px")};
    padding-left: 5px;
`;

// Définition du composant fonction 'ButtonGetAllQuotesInfo'
const ButtonGetAllQuotesInfo = () => {
    // Theme pour la gestion du mode jour et nuit
    const { theme } = useTheme();
    // UseState de l'url de la page des actions
    const [urlActions, definirUrlActions] = useState("");
    // identificationType pour le token pour la ou les requête(s)
    const { identificationType } = useIdentification();

    // UseState de l'url pour les requêtes Fetch
    const [url, definirUrl] = useState("");
    // UseState de l'objet init pour les requêtes Fetch
    const [fetchParamObjet, definirFetchParamObjet] = useState({});
    // UseState des informations sur la requête
    const [infoFetch, definirInfoFetch] = useState({
        typeFetch: "",
        donneesMessage: "",
        alerteMessage: "",
        erreurMessage: "",
    });
    // UseState du retour de la requête sur la récupération des valeurs des actions au quotidien
    const [recuperationAllQuotes, definirRecuperationAllQuotes] = useState([]);

    // Hook personnalisé pour effectuer les requêtes fetch
    const { donnees, enChargement, erreur } = useFetch(url, fetchParamObjet, infoFetch);

    // UseState pour le lancement de la requete de récupération de toutes les valeurs d'actions
    const [activationRecuperationAction, definirActivationRecuperationAction] = useState(false);

    // Déclanchement de la requête pour la récupération des valeurs des actions au quotidien
    useEffect(() => {
        if (urlActions !== "") {
            console.log("<----- REQUETE SUR ACTION ----->");
            const token = identificationType.token;
            definirUrl("https://bancauapp.lp-visite360.com/api/quotes/info");
            definirFetchParamObjet({
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    urlPage: urlActions,
                }),
            });
            definirInfoFetch({
                typeFetch: "getAllQuotesInfo",
                donneesMessage: "Récupération des valeurs des actions au quotidien lancée",
                alerteMessage: "Récupération des valeurs des actions au quotidien : ",
                erreurMessage: "Erreur pour la récupération des valeurs des actions au quotidien : [ ",
            });
        }
    }, [urlActions]);

    // Récupération lors d'une requête Fetch
    useEffect(() => {
        if (donnees !== 0 && infoFetch.typeFetch === "getAllQuotesInfo") {
            //console.log(" => récupération des messages terminée");
            console.log("<----- FIN REQUETE SUR ACTION ----->");
            definirRecuperationAllQuotes(donnees);
        }
    }, [donnees]);

    // Erreur lors d'une requête Fetch
    if (erreur) {
        return <span>Oups il y a eu un problème</span>;
    }

    return enChargement ? (
        <Chargement />
    ) : (
        <DivButtonContaineur $creationActive={activationRecuperationAction} $width="100%" theme={theme}>
            {!activationRecuperationAction ? (
                <StyleButton
                    theme={theme}
                    $styleCreation
                    $estFlex
                    onClick={() => {
                        definirActivationRecuperationAction(true);
                        definirUrlActions("https://www.lecho.be/les-marches/bourses/euronext-paris/frencha.html");
                    }}
                >
                    <FontAwesomeIcon className="normalIconInverse" icon="fa-solid fa-chart-line" />
                    <FontAwesomeIcon className="normalIconInverse" icon="fa-solid fa-chevron-right" />
                    <FontAwesomeIcon className="normalIconInverse" icon="fa-solid fa-database" />
                    <ButtonTexte $size="18px">Lancer la récupération des valeurs des actions au quotidien</ButtonTexte>
                </StyleButton>
            ) : (
                /*<span>Génial, il n'y a pas eu de problème</span>*/
                <ReponseBox
                    definirActivationRecuperationAction={definirActivationRecuperationAction}
                    recuperationAllQuotes={recuperationAllQuotes}
                    definirRecuperationAllQuotes={definirRecuperationAllQuotes}
                    definirUrlActions={definirUrlActions}
                />
            )}
        </DivButtonContaineur>
    );
};

export default ButtonGetAllQuotesInfo;
