/*-----------------------------------------------------------------------------------------------*/
/* Définition du composant 'ReponseBox' pour notre application React 'app' pour notre FrontEnd : */
/*-----------------------------------------------------------------------------------------------*/

/* Importation du module 'styled' de 'styled-components' */
import styled from "styled-components";
/* Importation des couleurs de notre style */
import couleurs from "../../../utils/style/couleurs";
/* Importation de notre style spécifique de 'button' */
import { StyleButton } from "../../../utils/style/Atomes";

/* Importation des icones FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* importation des hooks 'useContext', 'useState' et 'useEffect' de React */
import { useContext, useState, useEffect } from "react";

/* Importation de notre Hook 'useTheme' */
import { useTheme } from "../../../utils/hooks";

/* Importation de notre connexion context */
import { ConnexionContext } from "../../../utils/context";

const DivReponseBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 10px;
`;

const StyleTitre = styled.h2`
    margin: 18px 0 0 0;
    color: ${({ theme }) => (theme === "clair" ? couleurs.primaire : couleurs.secondaire)};
`;

const StyleContenu = styled.p`
    margin: 16px 0;
    border: solid ${({ theme }) => (theme === "clair" ? couleurs.fontClair : couleurs.fontSombre)} 1px;
    padding: 5px;
    color: ${({ theme }) => (theme === "clair" ? couleurs.fontClair : couleurs.fontSombre)};
    white-space: pre-wrap;
`;

const ButtonConteneur = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: ${({ $width }) => $width};
`;

const ButtonTexte = styled.span`
    font-size: ${({ $size }) => ($size ? $size : "12px")};
    padding-left: 5px;
`;

// Définition du composant fonction 'Message'
const Message = ({ definirActivationRecuperationAction, recuperationAllQuotes, definirRecuperationAllQuotes, definirUrlActions }) => {
    // Theme pour la gestion du mode jour et nuit
    const { theme } = useTheme();
    // Identification pour la récupération de l'id de l'utilisateur connecté
    const { identificationType } = useContext(ConnexionContext);

    // UseState du retour de la requête sur un message avec son id
    const [reponseRequete, definirReponseRequete] = useState("");

    // Déclenchement de l'affichage de la réponse de la requete
    useEffect(() => {
        let texte = `{\n\n`;
        for (const [key, value] of Object.entries(recuperationAllQuotes)) {
            texte += `    ${key}: ${value}\n\n`;
        }
        texte += `}`;
        definirReponseRequete(texte);
    }, [recuperationAllQuotes]);

    return (
        <DivReponseBox>
            <StyleTitre theme={theme}>
                <FontAwesomeIcon className="normalIconTitle" icon="fa-solid fa-chart-line" />
                <FontAwesomeIcon className="normalIconTitle" icon="fa-solid fa-chevron-right" />
                <FontAwesomeIcon className="normalIconTitle" icon="fa-solid fa-database" />
                <span>Réponse de la requête de récupération des valeurs des actions au quotidien</span>
            </StyleTitre>
            <StyleContenu theme={theme}>{reponseRequete}</StyleContenu>
            <ButtonConteneur $width="100%">
                <StyleButton
                    theme={theme}
                    $styleCreation
                    $estFlex
                    onClick={() => {
                        definirActivationRecuperationAction(false);
                        definirRecuperationAllQuotes([]);
                        definirUrlActions("");
                    }}
                >
                    <FontAwesomeIcon icon="fa-solid fa-rotate-left" />
                    <ButtonTexte $size="13px">Revenir</ButtonTexte>
                </StyleButton>
            </ButtonConteneur>
        </DivReponseBox>
    );
};

export default Message;
