/*-----------------------------------------------------------------------------------------------------*/
/* Définition du composant 'RequeteSurAction' pour notre application React 'app' pour notre FrontEnd : */
/*-----------------------------------------------------------------------------------------------------*/

/* Importation du module 'styled' de 'styled-components' */
import styled from "styled-components";
/* Importation des couleurs de notre style */
import couleurs from "../../../utils/style/couleurs";

/* Importation de notre composant 'Chargement' */
import Chargement from "../../Chargement";
/* Importation de notre composant 'ButtonCreation' */
import ButtonGetAllQuotesInfo from "../../Actions/GetAllQuotesInfo";

/* Importation de nos Hook personnalisés 'useTheme', 'useFetch' et 'useIdentification' */
import { useTheme } from "../../../utils/hooks";

const RequeteSurActionContainer = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
`;

const NoStyledLi = styled.li`
    margin: 10px 0;
    padding: 10px;
    border-radius: 20px;
    background-color: ${({ theme }) => (theme === "clair" ? couleurs.secondaire : couleurs.tertiaire)};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

// Définition du composant fonction 'RequeteSurAction'
const RequeteSurAction = () => {
    // Theme pour la gestion du mode jour et nuit
    const { theme } = useTheme();
    // identificationType pour le token pour la requête d'obtention de tous les messages
    //const { identificationType } = useIdentification();

    // UseState pour la modification d'un message
    //const [modificationMessageActive, definirModificationMessageActive] = useState(false);

    // UseState de l'url pour les requêtes Fetch
    //const [url, definirUrl] = useState("");
    // UseState de l'objet init pour les requêtes Fetch
    //const [fetchParamObjet, definirFetchParamObjet] = useState({});
    // UseState des informations sur la requête
    /*const [infoFetch, definirInfoFetch] = useState({
        typeFetch: "",
        donneesMessage: "",
        alerteMessage: "",
        erreurMessage: "",
    });*/
    // UseState du retour de la requête sur tous les messages
    //const [listeMessages, definirListeMessages] = useState([]);

    // Hook personnalisé pour effectuer les requêtes fetch
    //const { donnees, enChargement, erreur } = useFetch(url, fetchParamObjet, infoFetch);

    // Déclanchement initial de la requête pour obtenir les informations de tous les message
    /*useEffect(() => {
        if (url === "") {
            console.log("<----- REQUETE SUR ACTION ----->");
            const token = identificationType.token;
            definirUrl("https://bancauapp.lp-visite360.com/api/posts");
            definirFetchParamObjet({
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            definirInfoFetch({
                typeFetch: "getAllMessages",
                donneesMessage: "Récupération de tous les messages terminée",
                alerteMessage: "Consultation des messages : ",
                erreurMessage: "Erreur pour la consultation des messages : [ ",
            });
        }
    }, []);*/

    // Récupération lors d'une requête Fetch
    /*useEffect(() => {
        if (donnees !== 0 && infoFetch.typeFetch === "getAllMessages") {
            //console.log(" => récupération des messages terminée");
            console.log("<----- FIN REQUETE SUR ACTION ----->");
            definirListeMessages(donnees);
        }
    }, [donnees]);*/

    // Erreur lors d'une requête Fetch
    /*if (erreur) {
        return <span>Oups il y a eu un problème</span>;
    }*/

    // Variable temporaire pour éventuellement besoin d'affiché l'animation de chargement
    const enChargement = false;

    return enChargement ? (
        <Chargement />
    ) : (
        <RequeteSurActionContainer>
            <NoStyledLi theme={theme}>
                <ButtonGetAllQuotesInfo />
            </NoStyledLi>
        </RequeteSurActionContainer>
    );
};

export default RequeteSurAction;
